import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicService } from './services/public.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'home-tech';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public publicService: PublicService
  ) {
    if (this.router.url == '/') {
      this.router.navigateByUrl('/home/1');
    }
  }
  
  ngOnInit() {
    // this.publicService.show
  }  
  // 2b99589e-c777-49be-9e19-072252a69194 prod id
  // 4a8c0e16-e9ff-4ad9-8dc9-03e6f24aec1b local id
}

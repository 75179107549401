import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { MaterialExampleModule } from './material.module';

import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FinalScreenComponent } from './final-screen/final-screen.component';
import { LoaderComponent } from './loader/loader.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent, LoaderComponent, FinalScreenComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MaterialExampleModule,
    MatNativeDateModule,
    MaterialExampleModule,
    ReactiveFormsModule,
    /* configure App with AmplifyAuthenticatorModule */
    // AmplifyAuthenticatorModule,
    RecaptchaModule, //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    // GoogleTagManagerModule.forRoot({
    //   id: 'GTM-PL9CR92',
    // }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: 'googleTagManagerId', useValue: 'GTM-PL9CR92' },
    // GoogleTagManagerService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}

import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  showLoading: boolean = true;
  constructor(private spinnerService: NgxSpinnerService) {}

  showSpinner() {
    // this.showLoading = true;
    this.spinnerService.show();
  }

  hideSpinner() {
    // this.showLoading = true;
    this.spinnerService.hide();
  }

  scrollToView(el: Element){
    el.scrollIntoView({behavior: 'smooth',block:'end',inline:"nearest"});
  }

  scrollToInvalidField(formref ,selector:string) {
    const firstInvalidField = formref.nativeElement.querySelector(selector);
    console.log(firstInvalidField)
    if (firstInvalidField) {
      firstInvalidField.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

<div class="page-content" >

  <div class=" thanking-page-div">
    <div class="logo-thanking-page d-flex justify-content-center">
      <i class="fas fa-check-circle fa-6x" style="color: #017aff;height: 90px;width: 90px;"></i>
    </div>
    <div class="row">
      <div class="col-md-12  text-center">
        <div class="finish-message-section" style="width: 80%; margin-left: 10%;">
          <div class="finish-message-container">
            <h6 class="display-6 thank-text">Thanks for booking an appointment.<br></h6>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12  text-center">
        <h4 class="ease-steps"><span>Review details</span> <span class="greaterthan-icon"><i
              class="fa-solid fa-greater-than" style="color:#017aff;"></i></span>
          <span>Technician Appointed</span> <span class="greaterthan-icon"> <i
              class="fa-solid fa-greater-than" style="color:#017aff;"></i></span>
          <span>Confirm Appointment</span> <span class="greaterthan-icon"><i class="fa-solid fa-greater-than"
              style="color:#017aff;"></i></span>
          <span>Task Finished</span> <span class="greaterthan-icon"><i class="fa-solid fa-greater-than"
              style="color:#017aff;"></i></span>
          <span>Feedback Requested</span>
        </h4>
      </div>
    </div>
    <div class="row ">
      <div class="steps-container d-flex justify-content-center">
        <div class="work-step step-review">
          <div class="work-title-section">
            <h4 class="ease-step-title"><span><i class="fa fa-file-alt "
                  style="color:#017aff"></i></span>&nbsp;&nbsp;Review Details</h4>
          </div>
          <p class="ease-step-description">Expect a confirmation message to your phone number and a service
            agreement delivered to your email upon booking a successful appointment.</p>
        </div>
        <div class="work-step step-technician">
          <div class="work-title-section">
            <h4 class="ease-step-title"><span><i class="fa-solid fa-user-nurse"
                  style="color:#017aff"></i></span>&nbsp;&nbsp;Technician Appointed</h4>
          </div>
          <p class="ease-step-description">A qualified technician will be assigned to your appointment and
            notified of the scheduled date and time. </p>
        </div>
        <div class="work-step step-confirmation">
          <div class="work-title-section">
            <h4 class="ease-step-title"><span><i class="fa-solid fa-circle-check"
                  style="color:#017aff"></i></span>&nbsp; Confirm Appointment</h4>
          </div>
          <p class="ease-step-description">Please take a moment to review and sign the service agreement sent
            to
            your email prior to the appointment.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="steps-container d-flex justify-content-center">
        <div class="work-step step-technician">
          <div class="work-title-section">
            <h4 class="ease-step-title"><span><i class="fa-solid fa-screwdriver-wrench"
                  style="color:#017aff"></i></span>&nbsp;&nbsp; Task Finished</h4>
          </div>
          <p class="ease-step-description">Our skilled technicians will arrive at your location, ensuring a
            seamless installation of your TVS, tailored to your unique needs.</p>
        </div>
        <div class="work-step step-technician">
          <div class="work-title-section">
            <h4 class="ease-step-title"><span><i class="fa-solid fa-thumbs-up"
                  style="color:#017aff;"></i></span>&nbsp;&nbsp;Feedback Requested</h4>
          </div>
          <p class="ease-step-description">We appreciate your feedback! Kindly share your experience with our
            service by leaving us a review on your preferred platform. Your thoughts will help us continue to
            provide exceptional customer experiences.</p>
        </div>

      </div>
    </div>



  </div>
</div>

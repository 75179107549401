import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MaterialExampleModule } from '../material.module';
import { FooterComponent } from './components/footer/footer.component';
import { MaterialCustomDialogComponent } from './components/material-custom-dialog/material-custom-dialog.component';
import { AlphabetsOnlyDirective } from './directives/alphabetsonly.directive';
import { NumbersonlyDirective } from './directives/numbersonly.directive';
import { PatternCheck } from './directives/pattern-check.directive';

@NgModule({
  declarations: [
    NumbersonlyDirective,
    MaterialCustomDialogComponent,
    PatternCheck,
    AlphabetsOnlyDirective,
    FooterComponent,
  ],
  imports: [CommonModule],
  exports: [
    NumbersonlyDirective,
    MaterialCustomDialogComponent,
    MaterialExampleModule,
    PatternCheck,
    AlphabetsOnlyDirective,
    FooterComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
